'use client';
import {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from 'react';

type EventData = {
  message?: string;
  timestamp?: string;
  value?: number;
  event?: string;
  data?: any;
};

type SseContextType = {
  events: EventData[];
  status: 'connecting' | 'open' | 'closed';
  error: Error | null;
  clearEvents: () => void;
};

const SseContext = createContext<SseContextType | undefined>(undefined);

export function SseProvider({ children }: { children: ReactNode }) {
  const [events, setEvents] = useState<EventData[]>([]);
  const [status, setStatus] = useState<'connecting' | 'open' | 'closed'>(
    'connecting',
  );
  const [error, setError] = useState<Error | null>(null);

  const clearEvents = () => setEvents([]);

  useEffect(() => {
    const eventSource = new EventSource('/api/sse');

    const handleMessage = (e: MessageEvent) => {
      try {
        const data: EventData = JSON.parse(e.data);
        setEvents((prev) => [...prev, data]);
      } catch (err) {
        setError(err instanceof Error ? err : new Error('Erro desconhecido'));
      }
    };

    eventSource.onopen = () => setStatus('open');
    eventSource.onmessage = handleMessage;

    // Eventos customizados
    eventSource.addEventListener('connected', handleMessage);
    eventSource.addEventListener('update', handleMessage);

    eventSource.onerror = () => {
      setStatus('closed');
      setError(new Error('Erro na conexão SSE'));
      eventSource.close();
    };

    return () => {
      eventSource.close();
      setStatus('closed');
    };
  }, []);

  return (
    <SseContext.Provider value={{ events, status, error, clearEvents }}>
      {children}
    </SseContext.Provider>
  );
}

export function useSse() {
  const context = useContext(SseContext);
  if (context === undefined) {
    throw new Error('useSse deve ser usado dentro de um SseProvider');
  }
  return context;
}
