import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/admin/prodmob-webapp/mob-webapp/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/admin/prodmob-webapp/mob-webapp/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/admin/prodmob-webapp/mob-webapp/src/components/layout/Footer/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/admin/prodmob-webapp/mob-webapp/src/components/layout/Header/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SearchProvider"] */ "/home/admin/prodmob-webapp/mob-webapp/src/contexts/SearchContext/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SseProvider"] */ "/home/admin/prodmob-webapp/mob-webapp/src/contexts/SseContext/index.tsx");
